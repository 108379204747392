.go-top {
  z-index: 1 !important;
  position: fixed !important;
  bottom: 20px !important;
  right: 30px !important;
  font-size: 18px !important;
  background-color: #333 !important;
  color: #fff !important;
  border: none !important;
  outline: none !important;
  cursor: pointer !important;
  padding: 15px !important;
  border-radius: 4px !important;
}

.go-top:hover {
  background-color: #555 !important;
}