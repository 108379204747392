body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media (min-width: 1200) {
  .hidden-menu {
    display: none;
  }
}

.authorBorder {
  /*background-color: #F4F8FC;
    padding: 10px 180px 10px 10px;
    border-radius: 5px;
    margin-top: 30px;
    position: relative;*/

  background-color: #f4f8fc;
  padding: 50px 50px 50px 180px;
  border-radius: 5px;
  margin-top: 30px;
  position: relative;
}

.imgRounder {
  border-radius: 50% !important;
}

/*.align-items-center {
  align-items: top!important;
}*/

/* Apple Google Logo*/

.apple-logo {
  height: 46px;
  width: auto;
  object-fit: contain;
  display: block;
  margin-right: 10px;
}

.google-logo {
  height: 52px; /* Increase the height for the Google Play logo */
  width: auto;
  object-fit: contain;
  display: block;
  margin-right: 10px;
}

.found-on-text {
  font-size: 24px; /* Set the desired font size */
  font-weight: bold; /* Make the text bold */
  color: #333; /* Set the desired font color */
  margin-bottom: 10px; /* Add some spacing below the text */
}
