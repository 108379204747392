
.portfolio-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.portfolio-detail h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.portfolio-detail p {
  font-size: 18px;
  margin-bottom: 20px;
}

.portfolio-detail img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.portfolio-detail .tag-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.portfolio-detail .tag {
  background-color: #f5f5f5;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 14px;
}

.portfolio-detail .tag:last-child {
  margin-right: 0;
}

.portfolio-detail .portfolio-section-snippet {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.portfolio-detail .portfolio-section-snippet h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.portfolio-detail .portfolio-section-snippet p {
  font-size: 16px;
  margin-bottom: 20px;
}

.portfolio-detail .portfolio-section-snippet a {
  display: inline-block;
  padding: 10px 20px;
  background-color: #0077cc;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.2s ease-in-out;
}

.portfolio-detail .portfolio-section-snippet a:hover {
  background-color: #005ea8;
}

.portfolio-detail img {
    max-width: 100%;
    height: auto;
  } 

  


.portfolio-section-snippets {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
}

@media screen and (max-width: 768px) {
  .portfolio-section-snippets {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media screen and (max-width: 480px) {
  .portfolio-section-snippets {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .portfolio-section-snippets .portfolio-section-snippet {
    padding: 10px;
  }

  .portfolio-section-snippets .portfolio-section-snippet h2 {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .portfolio-section-snippets .portfolio-section-snippet p {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .portfolio-section-snippets .portfolio-section-snippet a {
    padding: 5px 10px;
    font-size: 12px;
  }
}