pre {
  position: relative;
}

.copy-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.copy-button {
  background-color: #007acc;
  color: #fff;
  border: none;
  border-radius: 0.25rem;
  padding: 0.5rem;
  cursor: pointer;
}

.copy-button:hover {
  background-color: #0062cc;
}

.copy-button:active {
  background-color: #005cbf;
}

.copy-button:focus {
  outline: none;
}

code {
  font-family: "Courier New", Courier, monospace;
}