
/* Light mode */
.pagination-light {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination-light li {
  display: inline-block;
  margin: 0 5px;
}

.pagination-light li a {
  display: block;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #333;
  text-decoration: none;
}

.pagination-light li.active-light a {
  background-color: #007bff;
  color: #fff;
}

.pagination-light li.disabled a {
  color: #bfbdbd;
  cursor: not-allowed;
}


/* Dark mode */
.pagination-dark {
display: flex;
justify-content: center;
margin-top: 20px;

}

.pagination-dark li {
display: inline-block;
margin: 0 5px;
}

.pagination-dark li a {
display: block;
padding: 5px 10px;
border: 1px solid #ccc;
border-radius: 5px;
color: #fff;
text-decoration: none;
}

.pagination-dark li.active-dark a {
background-color: #007bff;
color: #fff;
}

.pagination-dark li.disabled a {
color: #bfbdbd;
cursor: not-allowed;
}


