.app-screenshot {
  text-align: center;
}

.app-screenshot img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 767px) {
    .app-info {
        padding: 20px;
    }
}

.app-info h3 {
  font-size: 24px;
  margin-top: 0;
}

.app-info p {
  font-size: 16px;
  margin-bottom: 20px;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0069d9;
  border-color: #0062cc;
}

.app-screenshot img {
  max-width: 100%;
  height: auto;
}