/* PrismJS 1.22.0
https://prismjs.com/download.html#themes=prism-okaidia&languages=markup+css+clike+javascript+arduino+c+csharp+cpp+css-extras+excel-formula+go+http+java+kotlin+matlab+objectivec+prolog+python+r+jsx+tsx+regex+swift+typescript+vim&plugins=line-highlight+line-numbers+show-language+jsonp-highlight+highlight-keywords+previewers+keep-markup+toolbar+download-button+match-braces */
/**
 * okaidia theme for JavaScript, CSS and HTML
 * Loosely based on Monokai textmate theme by http://www.monokai.nl/
 * @author ocodia
 */

 code[class*="language-"],
 pre[class*="language-"] {
   color: #f8f8f2;
   background: none;
   text-shadow: 0 1px rgba(0, 0, 0, 0.3);
   font-family: Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
   font-size: 1em;
   text-align: left;
   white-space: pre;
   word-spacing: normal;
   word-break: normal;
   word-wrap: normal;
   line-height: 1.5;
 
   -moz-tab-size: 4;
   -o-tab-size: 4;
   tab-size: 4;
 
   -webkit-hyphens: none;
   -moz-hyphens: none;
   -ms-hyphens: none;
   hyphens: none;
 }
 
 /* Code blocks */
 pre[class*="language-"] {
   padding: 1em;
   margin: 0.5em 0;
   overflow: auto;
   border-radius: 0.3em;
 }
 
 :not(pre) > code[class*="language-"],
 pre[class*="language-"] {
   background: #272822;
 }
 
 /* Inline code */
 :not(pre) > code[class*="language-"] {
   padding: 0.1em;
   border-radius: 0.3em;
   white-space: normal;
 }
 
 .token.comment,
 .token.prolog,
 .token.doctype,
 .token.cdata {
   color: #8292a2;
 }
 
 .token.punctuation {
   color: #f8f8f2;
 }
 
 .token.namespace {
   opacity: 0.7;
 }
 
 .token.property,
 .token.tag,
 .token.constant,
 .token.symbol,
 .token.deleted {
   color: #f92672;
 }
 
 .token.boolean,
 .token.number {
   color: #ae81ff;
 }
 
 .token.selector,
 .token.attr-name,
 .token.string,
 .token.char,
 .token.builtin,
 .token.inserted {
   color: #a6e22e;
 }
 
 .token.operator,
 .token.entity,
 .token.url,
 .language-css .token.string,
 .style .token.string,
 .token.variable {
   color: #f8f8f2;
 }
 
 .token.atrule,
 .token.attr-value,
 .token.function,
 .token.class-name {
   color: #e6db74;
 }
 
 .token.keyword {
   color: #66d9ef;
 }
 
 .token.regex,
 .token.important {
   color: #fd971f;
 }
 
 .token.important,
 .token.bold {
   font-weight: bold;
 }
 .token.italic {
   font-style: italic;
 }
 
 .token.entity {
   cursor: help;
 }
 
 pre[data-line] {
   position: relative;
   padding: 1em 0 1em 3em;
 }
 
 .line-highlight {
   position: absolute;
   left: 0;
   right: 0;
   padding: inherit 0;
   margin-top: 1em; /* Same as .prismâ€™s padding-top */
 
   background: hsla(24, 20%, 50%, 0.08);
   background: linear-gradient(
     to right,
     hsla(24, 20%, 50%, 0.1) 70%,
     hsla(24, 20%, 50%, 0)
   );
 
   pointer-events: none;
 
   line-height: inherit;
   white-space: pre;
 }
 
 @media print {
   .line-highlight {
     /*
          * This will prevent browsers from replacing the background color with white.
          * It's necessary because the element is layered on top of the displayed code.
          */
     -webkit-print-color-adjust: exact;
     color-adjust: exact;
   }
 }
 
 .line-highlight:before,
 .line-highlight[data-end]:after {
   content: attr(data-start);
   position: absolute;
   top: 0.4em;
   left: 0.6em;
   min-width: 1em;
   padding: 0 0.5em;
   background-color: hsla(24, 20%, 50%, 0.4);
   color: hsl(24, 20%, 95%);
   font: bold 65%/1.5 sans-serif;
   text-align: center;
   vertical-align: 0.3em;
   border-radius: 999px;
   text-shadow: none;
   box-shadow: 0 1px white;
 }
 
 .line-highlight[data-end]:after {
   content: attr(data-end);
   top: auto;
   bottom: 0.4em;
 }
 
 .line-numbers .line-highlight:before,
 .line-numbers .line-highlight:after {
   content: none;
 }
 
 pre[id].linkable-line-numbers span.line-numbers-rows {
   pointer-events: all;
 }
 pre[id].linkable-line-numbers span.line-numbers-rows > span:before {
   cursor: pointer;
 }
 pre[id].linkable-line-numbers span.line-numbers-rows > span:hover:before {
   background-color: rgba(128, 128, 128, 0.2);
 }
 
 pre[class*="language-"].line-numbers {
   position: relative;
   padding-left: 3.8em;
   counter-reset: linenumber;
 }
 
 pre[class*="language-"].line-numbers > code {
   position: relative;
   white-space: inherit;
 }
 
 .line-numbers .line-numbers-rows {
   position: absolute;
   pointer-events: none;
   top: 0;
   font-size: 100%;
   left: -3.8em;
   width: 3em; /* works for line-numbers below 1000 lines */
   letter-spacing: -1px;
   border-right: 1px solid #999;
 
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
 }
 
 .line-numbers-rows > span {
   display: block;
   counter-increment: linenumber;
 }
 
 .line-numbers-rows > span:before {
   content: counter(linenumber);
   color: #999;
   display: block;
   padding-right: 0.8em;
   text-align: right;
 }
 
 div.code-toolbar {
   position: relative;
 }
 
 div.code-toolbar > .toolbar {
   position: absolute;
   top: 0.3em;
   right: 0.2em;
   transition: opacity 0.3s ease-in-out;
   opacity: 0;
 }
 
 div.code-toolbar:hover > .toolbar {
   opacity: 1;
 }
 
 /* Separate line b/c rules are thrown out if selector is invalid.
    IE11 and old Edge versions don't support :focus-within. */
 div.code-toolbar:focus-within > .toolbar {
   opacity: 1;
 }
 
 div.code-toolbar > .toolbar .toolbar-item {
   display: inline-block;
 }
 
 div.code-toolbar > .toolbar a {
   cursor: pointer;
 }
 
 div.code-toolbar > .toolbar button {
   background: none;
   border: 0;
   color: inherit;
   font: inherit;
   line-height: normal;
   overflow: visible;
   padding: 0;
   -webkit-user-select: none; /* for button */
   -moz-user-select: none;
   -ms-user-select: none;
 }
 
 div.code-toolbar > .toolbar a,
 div.code-toolbar > .toolbar button,
 div.code-toolbar > .toolbar span {
   color: #bbb;
   font-size: 0.8em;
   padding: 0 0.5em;
   background: #f5f2f0;
   background: rgba(224, 224, 224, 0.2);
   box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
   border-radius: 0.5em;
 }
 
 div.code-toolbar > .toolbar a:hover,
 div.code-toolbar > .toolbar a:focus,
 div.code-toolbar > .toolbar button:hover,
 div.code-toolbar > .toolbar button:focus,
 div.code-toolbar > .toolbar span:hover,
 div.code-toolbar > .toolbar span:focus {
   color: inherit;
   text-decoration: none;
 }
 
 .prism-previewer,
 .prism-previewer:before,
 .prism-previewer:after {
   position: absolute;
   pointer-events: none;
 }
 .prism-previewer,
 .prism-previewer:after {
   left: 50%;
 }
 .prism-previewer {
   margin-top: -48px;
   width: 32px;
   height: 32px;
   margin-left: -16px;
 
   opacity: 0;
   -webkit-transition: opacity 0.25s;
   -o-transition: opacity 0.25s;
   transition: opacity 0.25s;
 }
 .prism-previewer.flipped {
   margin-top: 0;
   margin-bottom: -48px;
 }
 .prism-previewer:before,
 .prism-previewer:after {
   content: "";
   position: absolute;
   pointer-events: none;
 }
 .prism-previewer:before {
   top: -5px;
   right: -5px;
   left: -5px;
   bottom: -5px;
   border-radius: 10px;
   border: 5px solid #fff;
   box-shadow: 0 0 3px rgba(0, 0, 0, 0.5) inset, 0 0 10px rgba(0, 0, 0, 0.75);
 }
 .prism-previewer:after {
   top: 100%;
   width: 0;
   height: 0;
   margin: 5px 0 0 -7px;
   border: 7px solid transparent;
   border-color: rgba(255, 0, 0, 0);
   border-top-color: #fff;
 }
 .prism-previewer.flipped:after {
   top: auto;
   bottom: 100%;
   margin-top: 0;
   margin-bottom: 5px;
   border-top-color: rgba(255, 0, 0, 0);
   border-bottom-color: #fff;
 }
 .prism-previewer.active {
   opacity: 1;
 }
 
 .prism-previewer-angle:before {
   border-radius: 50%;
   background: #fff;
 }
 .prism-previewer-angle:after {
   margin-top: 4px;
 }
 .prism-previewer-angle svg {
   width: 32px;
   height: 32px;
   -webkit-transform: rotate(-90deg);
   -moz-transform: rotate(-90deg);
   -ms-transform: rotate(-90deg);
   -o-transform: rotate(-90deg);
   transform: rotate(-90deg);
 }
 .prism-previewer-angle[data-negative] svg {
   -webkit-transform: scaleX(-1) rotate(-90deg);
   -moz-transform: scaleX(-1) rotate(-90deg);
   -ms-transform: scaleX(-1) rotate(-90deg);
   -o-transform: scaleX(-1) rotate(-90deg);
   transform: scaleX(-1) rotate(-90deg);
 }
 .prism-previewer-angle circle {
   fill: transparent;
   stroke: hsl(200, 10%, 20%);
   stroke-opacity: 0.9;
   stroke-width: 32;
   stroke-dasharray: 0, 500;
 }
 
 .prism-previewer-gradient {
   background-image: linear-gradient(
       45deg,
       #bbb 25%,
       transparent 25%,
       transparent 75%,
       #bbb 75%,
       #bbb
     ),
     linear-gradient(45deg, #bbb 25%, #eee 25%, #eee 75%, #bbb 75%, #bbb);
   background-size: 10px 10px;
   background-position: 0 0, 5px 5px;
 
   width: 64px;
   margin-left: -32px;
 }
 .prism-previewer-gradient:before {
   content: none;
 }
 .prism-previewer-gradient div {
   position: absolute;
   top: -5px;
   left: -5px;
   right: -5px;
   bottom: -5px;
   border-radius: 10px;
   border: 5px solid #fff;
   box-shadow: 0 0 3px rgba(0, 0, 0, 0.5) inset, 0 0 10px rgba(0, 0, 0, 0.75);
 }
 
 .prism-previewer-color {
   background-image: linear-gradient(
       45deg,
       #bbb 25%,
       transparent 25%,
       transparent 75%,
       #bbb 75%,
       #bbb
     ),
     linear-gradient(45deg, #bbb 25%, #eee 25%, #eee 75%, #bbb 75%, #bbb);
   background-size: 10px 10px;
   background-position: 0 0, 5px 5px;
 }
 .prism-previewer-color:before {
   background-color: inherit;
   background-clip: padding-box;
 }
 
 .prism-previewer-easing {
   margin-top: -76px;
   margin-left: -30px;
   width: 60px;
   height: 60px;
   background: #333;
 }
 .prism-previewer-easing.flipped {
   margin-bottom: -116px;
 }
 .prism-previewer-easing svg {
   width: 60px;
   height: 60px;
 }
 .prism-previewer-easing circle {
   fill: hsl(200, 10%, 20%);
   stroke: white;
 }
 .prism-previewer-easing path {
   fill: none;
   stroke: white;
   stroke-linecap: round;
   stroke-width: 4;
 }
 .prism-previewer-easing line {
   stroke: white;
   stroke-opacity: 0.5;
   stroke-width: 2;
 }
 
 @-webkit-keyframes prism-previewer-time {
   0% {
     stroke-dasharray: 0, 500;
     stroke-dashoffset: 0;
   }
   50% {
     stroke-dasharray: 100, 500;
     stroke-dashoffset: 0;
   }
   100% {
     stroke-dasharray: 0, 500;
     stroke-dashoffset: -100;
   }
 }
 
 @-o-keyframes prism-previewer-time {
   0% {
     stroke-dasharray: 0, 500;
     stroke-dashoffset: 0;
   }
   50% {
     stroke-dasharray: 100, 500;
     stroke-dashoffset: 0;
   }
   100% {
     stroke-dasharray: 0, 500;
     stroke-dashoffset: -100;
   }
 }
 
 @-moz-keyframes prism-previewer-time {
   0% {
     stroke-dasharray: 0, 500;
     stroke-dashoffset: 0;
   }
   50% {
     stroke-dasharray: 100, 500;
     stroke-dashoffset: 0;
   }
   100% {
     stroke-dasharray: 0, 500;
     stroke-dashoffset: -100;
   }
 }
 
 @keyframes prism-previewer-time {
   0% {
     stroke-dasharray: 0, 500;
     stroke-dashoffset: 0;
   }
   50% {
     stroke-dasharray: 100, 500;
     stroke-dashoffset: 0;
   }
   100% {
     stroke-dasharray: 0, 500;
     stroke-dashoffset: -100;
   }
 }
 
 .prism-previewer-time:before {
   border-radius: 50%;
   background: #fff;
 }
 .prism-previewer-time:after {
   margin-top: 4px;
 }
 .prism-previewer-time svg {
   width: 32px;
   height: 32px;
   -webkit-transform: rotate(-90deg);
   -moz-transform: rotate(-90deg);
   -ms-transform: rotate(-90deg);
   -o-transform: rotate(-90deg);
   transform: rotate(-90deg);
 }
 .prism-previewer-time circle {
   fill: transparent;
   stroke: hsl(200, 10%, 20%);
   stroke-opacity: 0.9;
   stroke-width: 32;
   stroke-dasharray: 0, 500;
   stroke-dashoffset: 0;
   -webkit-animation: prism-previewer-time linear infinite 3s;
   -moz-animation: prism-previewer-time linear infinite 3s;
   -o-animation: prism-previewer-time linear infinite 3s;
   animation: prism-previewer-time linear infinite 3s;
 }
 .token.punctuation.brace-hover,
 .token.punctuation.brace-selected {
   outline: solid 1px;
 }
 
 .rainbow-braces .token.punctuation.brace-level-1,
 .rainbow-braces .token.punctuation.brace-level-5,
 .rainbow-braces .token.punctuation.brace-level-9 {
   color: #e50;
   opacity: 1;
 }
 .rainbow-braces .token.punctuation.brace-level-2,
 .rainbow-braces .token.punctuation.brace-level-6,
 .rainbow-braces .token.punctuation.brace-level-10 {
   color: #0b3;
   opacity: 1;
 }
 .rainbow-braces .token.punctuation.brace-level-3,
 .rainbow-braces .token.punctuation.brace-level-7,
 .rainbow-braces .token.punctuation.brace-level-11 {
   color: #26f;
   opacity: 1;
 }
 .rainbow-braces .token.punctuation.brace-level-4,
 .rainbow-braces .token.punctuation.brace-level-8,
 .rainbow-braces .token.punctuation.brace-level-12 {
   color: #e0e;
   opacity: 1;
 }
 